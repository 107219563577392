/* DrawingApp.css */
.drawing-canvas {
    border: 1px solid black;
    background-color: #ccc; /* Gray background */
    max-width: 100%;
  }
  
  .drawing-image {
    width: 200px;
    height: 150px;
    margin: 10px;
    background-color: #ccc; /* Gray background for images */
  }
  
  /* Additional styling for buttons, inputs, etc. */
  /* In your DrawingApp.css */
.tool-button {
    border: none;
    background-color: transparent;
    margin-right: 10px;
    font-size: 24px;
    color: #333;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .tool-button.active {
    color: #007bff; /* Change to your preferred active color */
  }

  /* In your DrawingApp.css */
.remove-button {
    margin-top: 5px;
  }

  /* In your DrawingApp.css */
.drawings-list {
    max-height: 300px; /* Adjust the maximum height as needed */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  
  .drawing-card {
    margin-bottom: 10px;
  }

  .drawing-card:last-child {
    margin-bottom: 30px; /* Add any desired margin value */
  }
  .container {
    max-height: calc(100vh - 20px); /* Adjust the value to set the maximum height of the container */
    overflow-y: auto;
  }